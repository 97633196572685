import {Grid, makeStyles} from '@material-ui/core'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import {
  Heading,
  Link,
  MobileDotBackgroundGrid,
  PageContainer,
} from '../components'
import {PageLayout} from '../layout'
import {GatsbyImage, SiteMetadata} from '../types'

export interface NotFoundPageProps {
  data: {
    site: {siteMetadata: SiteMetadata}
    accessDeniedImage: GatsbyImage
    mobileBackgroundImage: GatsbyImage
  }
}

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: 450,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
      marginBottom: theme.spacing(4),
    },
  },
  link: {
    display: 'inline-block',
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

export const NotFoundPage: React.FC<NotFoundPageProps> = ({data}) => {
  const {site, accessDeniedImage, mobileBackgroundImage} = data

  if (!site || !site.siteMetadata) {
    throw new Error(
      `Site metadata doesn't exist. Please fix it before opening this page.`,
    )
  }

  const classes = useStyles()

  return (
    <PageLayout
      title="Not Found"
      siteName={site.siteMetadata.title}
      description="The page you were looking for does not exist."
    >
      <PageContainer>
        <Grid container alignItems="center">
          <Grid item md={6} xs={12}>
            <Img
              fluid={accessDeniedImage.childImageSharp.fluid}
              className={classes.image}
              alt="A woman sitting on a couch-like object which is filled with a planet, a search icon and a map pin."
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Grid container direction="column">
              <Grid item>
                <Heading variant="h2" component="h1">
                  Page Not Found
                </Heading>
              </Grid>

              <Grid item>
                <Heading variant="h3" component="h2">
                  This is most likely a dead end.
                </Heading>
              </Grid>

              <Grid item>
                <Link to="/" className={classes.link}>
                  Go back to home page
                </Link>
              </Grid>

              <MobileDotBackgroundGrid
                fluidImage={mobileBackgroundImage.childImageSharp}
                alt="Opaque dot pattern"
              />
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </PageLayout>
  )
}

export const query = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    accessDeniedImage: file(relativePath: {eq: "lost-in-space-drawing.png"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileBackgroundImage: file(relativePath: {eq: "single-dot-drawing.png"}) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default NotFoundPage
